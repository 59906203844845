<template>
    <div class="">
      <center-title title="申请对账商品明细" :bottom="15"></center-title>
      <el-table :data="orderLists" >
        <el-table-column align="center" label="商品图片">
            <template slot-scope="scope">
                <img :src="scope.row.orderGoods[0].image" alt="商品图片" style="width: 100px; height: auto;" />
            </template>
        </el-table-column>
        <el-table-column align="center" label="商品名称">
            <template slot-scope="scope">
            {{ scope.row.orderGoods[0].goods_name }}
            </template>
        </el-table-column>
        <el-table-column align="center" prop="total_num" label="购买数量">
        </el-table-column>
        <el-table-column align="center" prop="goods_price" label="订单金额">
          <template slot-scope="scope">
            <make-priceformat :price="scope.row.goods_price" :subscriptSize="14" :firstSize="14" :secondSize="14" color="#333333"></make-priceformat>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="consignee" label="收货人"></el-table-column>
        <el-table-column align="center" prop="mobile" label="电话"></el-table-column>
        <el-table-column align="center" prop="deliveryAddress" label="收货地址"></el-table-column>
        <el-table-column align="center" prop="confirm_take_time" label="订单完成时间"></el-table-column>
      </el-table>
      <div class="row-center pagination mt30">
        <make-pagination
          @resetList="resetList"
          :orderObject="orderObject"
        ></make-pagination>
      </div>
    </div>
  </template>
  <script>
  import CenterTitle from "../components/CenterTitle";
  import MakePagination from "@/components/MakePagination";
  export default {
    name: "",
    props: {},
    components: {
      "center-title": CenterTitle,
      "make-pagination": MakePagination,
    },
    data() {
      return {
        orderLists: [],
        orderObject: {},
        page_no: 1,
        page_size: 10,
      };
    },
    computed: {},
    watch: {},
    methods: {
      getApplySettlementProductDetails() {
        this.$api
          .getApplySettlementProductDetailsApi({
            applySettlementId: this.$route.query.id,
            page_no: this.page_no,
            page_size: this.page_size,
          })
          .then((res) => {
            this.orderLists = res.data.lists;
            this.orderObject = res.data;
          });
      },
      resetList(e) {
        this.page_no = e;
        this.getApplySettlementProductDetails();
      },
    },
    created() {
      this.getApplySettlementProductDetails();
    },
    mounted() {},
    beforeDestroy() {},
  };
  </script>
  <style lang="scss" scoped>
  /deep/ .el-table {
    border: $-solid-border;
    border-bottom: none;
  
    th {
      background-color: $-color-center;
      font-size: 16px;
      font-weight: normal;
      color: $-color-lighter;
    }
  
    td {
      &:nth-child(2) {
        .cell {
          padding: 0 10px;
        }
      }
  
      .cell {
        padding: 0px 20px;
        line-height: 1.3;
        word-break: break-all;
      }
    }
  
    th,
    td {
      border-right: $-solid-border;
  
      &:last-child {
        border-right: none;
      }
    }
  }
  </style>
  